.main-heading {
  @apply text-[5rem] leading-[7.5rem] sm:text-[6.25rem] sm:leading-[9.375rem] lg:text-[7.5rem] lg:leading-[11.25rem];
}

.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  @apply font-larken font-semibold;
}

.text-h1 {
  @apply text-[3rem] leading-[4rem] sm:text-[4rem] sm:leading-[4.5rem] lg:text-[4.5rem] lg:leading-[6rem];
}

.text-h2 {
  @apply text-[2.5rem] leading-[3.5rem] sm:text-[3.25rem] sm:leading-[4.25rem] lg:text-[3.75rem] lg:leading-[5rem];
}

.text-h3 {
  @apply text-[2rem] leading-[2.625rem] sm:text-[2.625rem] sm:leading-[3.5rem] lg:text-[3rem] lg:leading-[4.5rem];
}

.text-h4 {
  @apply text-heading5 leading-[2.25rem] sm:text-[2.125rem] sm:leading-[3rem] lg:text-[2.25rem] lg:leading-[3.5rem];
}

.text-h5 {
  @apply text-heading6 leading-heading6 sm:text-heading5 sm:leading-mobile-large lg:text-heading4 lg:leading-mobile-large;
}

.text-h6 {
  @apply text-[1.125rem] leading-desktop-small sm:text-[1.25rem] sm:leading-[2rem] lg:text-heading6 lg:leading-[2rem];
}

.text-subheading {
  @apply text-paragraph font-semibold leading-paragraph tracking-[0.01em] lg:text-paragraph lg:leading-desktop-small;
}
