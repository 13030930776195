@font-face {
  font-family: 'Larken';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/fonts/Larken-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Larken';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/Larken-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Larken';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/fonts/Larken-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 325;
  font-display: swap;
  src: url('../public/fonts/Gotham-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 350;
  font-display: swap;
  src: url('../public/fonts/Gotham-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/Gotham-Bold.woff2') format('woff2');
}

.font-larken,
.font-gotham {
  @apply antialiased;
}
