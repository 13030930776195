@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @import 'fonts.css';
  @import 'typography.css';
  @import 'btn.css';
  @import 'input.css';
  @import 'datepicker.css';
  @import 'richtext.css';

  html,
  body {
    @apply font-gotham lg:overscroll-none;
  }

  .container {
    @apply px-4 sm:px-10;
  }
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.grecaptcha-badge {
  @apply hidden h-0 w-0;
}

.flexible-width {
  flex: 1;
}
.overlay::after,
.overlay-left-to-right::after {
  @apply pointer-events-none absolute left-0 top-0 h-full w-full content-[''];
}

.overlay::after {
  @apply bg-overlay;
}

.overlay-left-to-right::after {
  @apply bg-overlayLeftToRight;
}

.dupuble-fede-up {
  @apply relative overflow-hidden;
}

.dupuble-fede-up span {
  @apply relative flex h-full items-center after:translate-y-full after:opacity-0;
}

.dupuble-fede-up:not([disabled]) span {
  @apply text-transparent;
}

.dupuble-fede-up:hover span {
  @apply before:-translate-y-full before:opacity-0 after:translate-y-0 after:opacity-100;
}

.dupuble-fede-up span::after,
.dupuble-fede-up span::before {
  @apply absolute left-0 top-0 flex h-full w-full items-center justify-center text-[initial] transition-all duration-700 content-[attr(data-animation)];
}

.dupuble-fede-up:disabled span::after,
.dupuble-fede-up:disabled span::before {
  @apply hidden;
}

.person-modal-scrollbar::-webkit-scrollbar {
  width: 6px;
  transform: translateX(150%);
}

.person-modal-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}

.person-modal-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.25);
}
