.richtext-content {
  @apply w-full font-gotham text-small-paragraph font-s-light not-italic leading-desktop-small;
}

.richtext-content strong {
  @apply font-semibold;
}

.richtext-content h2 {
  @apply text-[40px] leading-[56px] sm:text-[52px] sm:leading-[68px] lg:text-[60px] lg:leading-[80px];
}

.richtext-content h3 {
  @apply text-[32px] leading-[42px] sm:text-[42px] sm:leading-[56px] lg:text-mobile-large lg:leading-[72px];
}

.richtext-content h4 {
  @apply text-heading5 leading-[36px] sm:text-[34px] sm:leading-[48px] lg:text-[36px] lg:leading-[56px];
}

.richtext-content h5 {
  @apply text-heading6 leading-heading6 sm:text-heading5 sm:leading-mobile-large lg:text-heading4 lg:leading-mobile-large;
}

.richtext-content h6 {
  @apply text-xl-paragraph leading-desktop-small sm:text-[20px] sm:leading-large-paragraph lg:text-heading6 lg:leading-large-paragraph;
}

.richtext-content .subHeading {
  @apply text-paragraph font-n-light leading-paragraph md:text-paragraph md:leading-desktop-small;
}

.richtext-content table {
  @apply mb-6 block w-full table-auto border-collapse border-spacing-0 overflow-x-auto whitespace-nowrap border-solid border-black text-left rtl:text-right;
}
.richtext-content tr,
.richtext-content th,
.richtext-content td {
  @apply border;
}
.richtext-content th,
.richtext-content td {
  @apply p-4;
}

.richtext-content blockquote {
  background: #eee;
  display: inline-block;
  padding: 1rem;
}
.richtext-content blockquote::before {
  content: '\201C';
}

.richtext-content blockquote::after {
  content: '\201D';
}

.richtext-content ul,
.richtext-content ol {
  list-style: revert;
  margin: revert;
  padding-left: 17px;
}

.richtext-content p {
  margin: revert;
}

.richtext-content a {
  @apply underline;
}
