.btn {
  @apply inline-flex h-12 cursor-pointer items-center justify-center rounded-sm font-gotham text-btn font-normal uppercase not-italic leading-tiny-paragraph transition-all duration-700;
}

.btn svg {
  @apply transition-all duration-700 first:mr-3 last:ml-3 only:mx-0;
}

.btn.primary {
  @apply border border-tan bg-tan px-8 py-3 text-black hover:border-bright-orange hover:bg-bright-orange active:border-bright-orange active:bg-bright-orange disabled:border-grey6 disabled:bg-grey6 disabled:text-black/20 [&_svg]:fill-black [&_svg]:disabled:fill-black/20;
}

.btn.primary.dark {
  @apply border border-deep-blue bg-deep-blue text-white hover:border-bright-orange hover:bg-bright-orange active:border-bright-orange active:bg-bright-orange disabled:border-grey6 disabled:bg-grey6 disabled:text-black/20 [&_span]:before:text-white [&_span]:after:text-white [&_svg]:fill-white [&_svg]:disabled:fill-black/20;
}

.btn.secondary {
  @apply border border-white/25 px-5 py-3 text-off-white hover:border-bright-orange hover:bg-bright-orange hover:text-white active:bg-bright-orange active:text-white disabled:border-grey5 disabled:bg-grey5 disabled:text-white/75 [&_span]:before:text-off-white [&_span]:after:text-off-white [&_svg]:fill-off-white [&_svg]:hover:fill-white [&_svg]:disabled:fill-white;
}

.btn.secondary.dark {
  @apply border-black/25 text-black/75 hover:border-bright-orange hover:text-off-white disabled:border-grey5 disabled:bg-grey5 disabled:text-black/25 [&_span]:before:text-black/75 [&_span]:after:text-black/75 [&_svg]:fill-black/75 [&_svg]:hover:fill-off-white;
}

.btn.secondary.dark:hover span {
  @apply before:text-off-white after:text-off-white;
}

.btn.general {
  @apply border border-transparent px-2 py-3 text-off-white before:ml-0 before:mr-3.5 before:w-2 before:transition-all before:duration-700 before:ease-in-out before:content-ellipse hover:before:ml-1 hover:before:mr-2.5 disabled:text-white/75 disabled:before:opacity-10 disabled:hover:before:ml-0 disabled:hover:before:mr-3.5 [&_span]:before:text-off-white [&_span]:after:text-off-white;
}

.btn.general.dark {
  @apply text-black disabled:text-black/25 [&_span]:before:text-black [&_span]:after:text-black;
}

.btn.text {
  @apply h-8 py-1 pl-0 pr-[21px] text-small-paragraph font-s-light normal-case leading-desktop-small text-black/50 hover:text-bright-orange active:text-bright-orange disabled:opacity-25 disabled:hover:text-black/50 [&_span]:before:text-black/50 [&_span]:after:text-black/50;
}

.btn.text:hover span {
  @apply before:text-bright-orange after:text-bright-orange;
}

.btn.text.dark {
  @apply text-white/50 hover:text-bright-orange disabled:hover:text-white/50 [&_span]:before:text-white/50 [&_span]:after:text-white/50;
}

.btn.text.dark:hover span {
  @apply before:text-bright-orange after:text-bright-orange;
}

.btn.icon {
  @apply flex h-12 w-12 shrink-0 items-center justify-center rounded-full p-3 hover:bg-white/75 disabled:hover:bg-transparent [&_svg]:fill-white [&_svg]:hover:fill-black/75 [&_svg]:disabled:fill-white/25;
}

.btn.icon.dark {
  @apply [&_svg]:fill-black/75 [&_svg]:disabled:fill-black/25;
}
